import React from "react";

const getMetrics = (liveCCU) => [
  {
    amount: "21K",
    label: "Peak Concurrent Users",
    style: "bg-hero-gradient hover:saturate-200 transition duration-200",
  },
  {
    amount: "72 Million",
    label: "Play Sessions",
    style: "bg-[#0D0D0D] hover:invert transition duration-200",
  },
  {
    amount: "30 Million",
    label: "Active Users",
    style: "bg-[#0D0D0D] hover:invert transition duration-200",
  },
  {
    amount: liveCCU,
    label: "Total Live Players",
    style: "bg-hero-gradient hue-rotate-180 hover:saturate-200 transition duration-200",
  },
];

const MetricCard = ({ amount, label, style }) => {
  return (
    <div
      className={`rounded-lg shadow-lg p-5 flex flex-col items-center justify-center text-white w-full ${style}`}
    >
      <span className="text-3xl font-extrabold uppercase">{amount}</span>
      <span className="text-white">{label}</span>
    </div>
  );
};

const MetricsSection = ({ liveCCU }) => {
  const metrics = getMetrics(liveCCU); // Use getMetrics function to get metrics data

  return (
    <section className="bg-black py-32 pb-24" id="metrics">
      <div className="container mx-auto px-4 text-center">
        <p className="border w-fit h-fit mx-auto px-4 py-2 rounded-xl border-opacity-15 border-white pb-2 mb-4 text-white text-lg">
          Metrics
        </p>
        <h2 className="text-5xl md:text-6xl font-black mt-4 text-white">
          Our Metrics
        </h2>
        <p className="text-lg mt-6 font-light text-gray-300 lg:w-6/12 w-10/12 mx-auto pb-12">
          We've worked across a number of genres and have achieved some
          incredible results with our games. In just three months of operation,
          we have reached these impressive milestones:
        </p>
        <div className="max-w-4xl mx-auto w-full flex flex-wrap justify-center items-stretch gap-4">
          <div className="w-full sm:w-auto flex-1 space-y-5">
            <MetricCard
              amount={metrics[0].amount}
              label={metrics[0].label}
              style={metrics[0].style}
            />
            <MetricCard
              amount={metrics[3].amount}
              label={metrics[3].label}
              style={metrics[3].style}
            />
          </div>
          <div className="w-full sm:w-auto flex-1 space-y-5 flex flex-col">
            <MetricCard
              amount={metrics[1].amount}
              label={metrics[1].label}
              style={metrics[1].style}
            />
            <MetricCard
              amount={metrics[2].amount}
              label={metrics[2].label}
              style={metrics[2].style}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default MetricsSection;
