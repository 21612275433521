// components/Games.js
import React, { useEffect, useState } from "react";
import axios from "axios";
import GameCarousel from "./utils/GameCarousel"; // Import the GameCarousel component
import GameGrid from "./utils/GameGrid"; // Import the GameGrid component
const Games = ({ setLiveCCU }) => {
  const [games, setGames] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Function to fetch games data
    const fetchGames = async () => {
      try {
        const response = await axios.get(
          "https://utopia.sparkle.gg/public/games-list"
        );
        setGames(response.data.games);
        setLoading(false);

        const totalLiveCCU = response.data.games.reduce(
          (acc, game) => acc + game.playing,
          0
        );
        setLiveCCU(totalLiveCCU.toLocaleString());
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    };

    fetchGames();
  }, [setLiveCCU]);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error loading games: {error.message}</p>;
  }

  return (
    <section className="bg-black py-32 pb-24" id="games">
      <div className="container mx-auto px-4 text-center">
        <p className="border w-fit h-fit mx-auto px-4 py-2 rounded-xl border-opacity-15 border-white pb-2 mb-4 text-white text-lg">
          Games
        </p>
        <h2 className="text-4xl md:text-6xl font-black mt-4 text-white">
          Games
        </h2>
        <p className="text-md md:text-lg mt-6 font-light text-gray-300 lg:w-6/12 w-10/12 mx-auto pb-12">
          At Sparkle, our mission is to explore every genre to captivate and
          entertain our players. We intend to conquer every genre.
        </p>
        <GameGrid games={games} /> {/* <GameCarousel games={games} />{" "} */}
        {/* Include the GameCarousel component */}
      </div>
    </section>
  );
};

export default Games;
