import React from "react";
import { motion } from "framer-motion"; // For animations
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const RippleButton = ({ children, className, href }) => {
  const handleRipple = (event) => {
    const button = event.currentTarget;

    // Create the circle span for the ripple effect
    const circle = document.createElement("span");
    const diameter = Math.max(button.clientWidth, button.clientHeight);
    const radius = diameter / 2;
    circle.style.width = circle.style.height = `${diameter}px`;
    circle.style.left = `${
      event.clientX - button.getBoundingClientRect().left - radius
    }px`;
    circle.style.top = `${
      event.clientY - button.getBoundingClientRect().top - radius
    }px`;
    circle.classList.add("ripple");

    // Remove any existing ripple before adding a new one
    const ripple = button.getElementsByClassName("ripple")[0];
    if (ripple) {
      ripple.remove();
    }

    button.appendChild(circle);

    // Automatically remove the ripple after it has animated
    setTimeout(() => {
      circle.remove();
    }, 600); // Should match animation duration
  };

  const Tag = href ? "a" : "button";

  return (
    <Tag
      className={`relative overflow-hidden ${className}`}
      onClick={handleRipple}
      href={href} // Only applies if Tag is 'a'
    >
      {children}
      <style jsx>{`
        .ripple {
          position: absolute;
          border-radius: 50%;
          transform: scale(0);
          animation: ripple 600ms linear;
          background-color: rgba(255, 255, 255, 0.7);
        }
        @keyframes ripple {
          to {
            transform: scale(4);
            opacity: 0;
          }
        }
      `}</style>
    </Tag>
  );
};

const logos = [
  "/logo1.png",
  "/logo2.png",
  "/logo3.png",
  "/logo4.png",
  // Add more logo paths here
];

const Hero = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 5000, // Adjust speed to make it slow
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 0, // Set autoplay speed to 0 to make it continuous
    cssEase: "linear", // Ensure smooth linear transition
    pauseOnHover: false, // Prevent pausing on hover,
    arrows: false, // Remove arrows
  };

  return (
    <section
      id="hero"
      className="hero bg-black text-white relative overflow-hidden"
    >
      <div className="container mx-auto p-6 text-center relative mt-24 z-50 w-full">
        <motion.h1
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="text-4xl md:text-6xl font-black mt-4 mx-auto lg:w-6/12 w-10/12"
        >
          We make the best experiences in the
          <span className="gradient-text font-black hover:saturate-200 transition duration-200">
            {" "}
            metaverse
          </span>
          .
        </motion.h1>
        <motion.p
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.5, duration: 0.8 }}
          className="text-md md:text-lg mt-6 font-light text-gray-300 lg:w-6/12 w-10/12 mx-auto"
        >
          We have been dedicated to developing and delivering
          high-quality gaming experiences, reaching over 30 million unique
          active users.
        </motion.p>
        <motion.div
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ delay: 1, duration: 0.5 }}
          className="mt-8 max-w-sm mx-auto flex flex-wrap justify-center gap-4"
        >
          <RippleButton
            className="bg-hero-gradient hover:saturate-150 text-white font-medium py-4 px-6 rounded-lg transition-all duration-200 w-full md:w-auto"
            href="#games"
          >
            Explore
          </RippleButton>
          <RippleButton
            href="#contact"
            className="bg-black/75 border border-[#232323] hover:bg-white hover:text-black text-white font-medium py-4 px-6 rounded-lg transition-all duration-200 w-full md:w-auto"
          >
            Contact us
          </RippleButton>
        </motion.div>
      </div>

      <motion.img
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ delay: 1, duration: 5, ease: "easeInOut" }}
        src="/asasasa.png"
        className="w-full md:w-6/12 mx-auto md:-mt-24"
      />

      {/* <div className="mt-10 max-w-3xl mx-auto">
        <Slider {...settings}>
          {logos.map((logo, index) => (
            <div key={index} className="p-2">
              <img src={logo} alt={`logo-${index}`} className="mx-auto grayscale" />
            </div>
          ))}
        </Slider>
      </div> */}

      <div>
        <p className="text-center text-sm mt-40 opacity-50">
          {/* slider here with game logo that have text/typography with gray scale */}
        </p>
      </div>
    </section>
  );
};

export default Hero;
