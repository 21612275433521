import React, { useEffect, useState } from "react";
import { motion } from "framer-motion"; // For animations

const RippleButton = ({ children, className, href }) => {
  const handleRipple = (event) => {
    const button = event.currentTarget;

    // Create the circle span for the ripple effect
    const circle = document.createElement("span");
    const diameter = Math.max(button.clientWidth, button.clientHeight);
    const radius = diameter / 2;
    circle.style.width = circle.style.height = `${diameter}px`;
    circle.style.left = `${
      event.clientX - button.getBoundingClientRect().left - radius
    }px`;
    circle.style.top = `${
      event.clientY - button.getBoundingClientRect().top - radius
    }px`;
    circle.classList.add("ripple");

    // Remove any existing ripple before adding a new one
    const ripple = button.getElementsByClassName("ripple")[0];
    if (ripple) {
      ripple.remove();
    }

    button.appendChild(circle);

    // Automatically remove the ripple after it has animated
    setTimeout(() => {
      circle.remove();
    }, 600); // Should match animation duration
  };

  const Tag = href ? "a" : "button";

  return (
    <Tag
      className={`relative overflow-hidden ${className}`}
      onClick={handleRipple}
      href={href} // Only applies if Tag is 'a'
    >
      {children}
      <style jsx>{`
        .ripple {
          position: absolute;
          border-radius: 50%;
          transform: scale(0);
          animation: ripple 600ms linear;
          background-color: rgba(255, 255, 255, 0.7);
        }
        @keyframes ripple {
          to {
            transform: scale(4);
            opacity: 0;
          }
        }
      `}</style>
    </Tag>
  );
};

const handleScrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({ behavior: "smooth" });
  }
};

const teamMembers = [
  {
    name: "strawhatvert",
    role: "cap n cook",
    userid: 3810754327,
  },
  {
    name: "teabled",
    role: "heisenburg",
    userid: 2366538947,
  },
  {
    name: "tafzri",
    role: "game producer",
    userid: 1057895133,
  },
  {
    name: "avwzz",
    role: "game producer",
    userid: 941184424,
  },
  {
    name: "Darkestendo",
    role: "game producer",
    userid: 69473909,
  },
  {
    name: "Syveloper",
    role: "game producer",
    userid: 35529078,
  },
];

const TeamMemberCard = ({ name, role, image, userid }) => {
  return (
    <div className="flex flex-col items-center justify-center p-4 hover:saturate-200 transition duration-0">
      <a href={`https://www.roblox.com/users/${userid}`} target="_blank" rel="noopener noreferrer">
        <div className="w-24 h-24 md:w-32 md:h-32 lg:w-40 lg:h-40 rounded-full overflow-hidden border-4 border-gradient-r-purple-pink-orange shadow-lg">
          <img
            src={image}
            alt={name}
            className="object-cover object-center w-full h-full"
          />
        </div>
        <h3 className="text-white mt-2 text-lg font-semibold">{name}</h3>
        <p className="text-gray-400">{role}</p>
      </a>
    </div>
  );
};

const Who = () => {
  const [team, setTeam] = useState(teamMembers);

  useEffect(() => {
    const fetchImages = async () => {
      const updatedTeam = await Promise.all(
        teamMembers.map(async (member) => {
          const response = await fetch(
            `https://api.allorigins.win/get?url=${encodeURIComponent(
              `https://thumbnails.roblox.com/v1/users/avatar-headshot?userIds=${member.userid}&size=420x420&format=Png&isCircular=false`
            )}`
          );
          const data = await response.json();
          const parsedData = JSON.parse(data.contents);
          const imageUrl = parsedData.data[0]?.imageUrl || member.image;
          return { ...member, image: imageUrl };
        })
      );
      setTeam(updatedTeam);
    };

    fetchImages();
  }, []);

  return (
    <>
      <section className="bg-black py-32 pb-24" id="team">
        <div className="container mx-auto px-4 text-center">
          <p className="border w-fit h-fit mx-auto px-4 py-2 rounded-xl border-opacity-15 border-white pb-2 mb-4 text-white text-lg">
            Team
          </p>
          <h2 className="text-5xl md:text-6xl font-black mt-4">
            Meet the Team
          </h2>
          <p className="text-md md:text-lg mt-6 font-light text-gray-300 lg:w-6/12 w-10/12 mx-auto pb-12">
            We are an independently owned game studio founded and operated by
            two passionate creators. Each member of our team brings a unique
            perspective to our games, resulting in a diverse and dynamic
            experience.
          </p>

          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.5, duration: 0.8 }}
            className="flex mx-auto justify-center items-center space-x-4"
          >
            <TeamMemberCard
              name={team[0].name}
              userid={team[0].userid}
              role={team[0].role}
              image={team[0].image}
            />
            <div className="vertical-line w-32 h-0.5 bg-white">&nbsp;</div>
            <TeamMemberCard
              name={team[1].name}
              role={team[1].role}
              image={team[1].image}
              userid={team[1].userid}
            />
          </motion.div>

          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 1, duration: 0.8 }}
            className="vertical-line w-0.5 h-52 lg:h-64 bg-white mx-auto -mt-[5.9rem] md:-mt-[6.9rem] lg:-mt-[7.9rem]"
          >
            &nbsp;
          </motion.div>

          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 1.5, duration: 0.8 }}
            className="flex mx-auto justify-center items-center -mt-[6.6rem] md:-mt-[6.9rem] lg:-mt-[7.9rem] space-x-4"
          >
            <TeamMemberCard
              name={team[2].name}
              role={team[2].role}
              image={team[2].image}
              userid={team[2].userid}
            />
            <div className="vertical-line w-96 h-0.5 bg-white">&nbsp;</div>
            <TeamMemberCard
              name={team[3].name}
              role={team[3].role}
              image={team[3].image}
              userid={team[3].userid}
            />
          </motion.div>

          {/* <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 2, duration: 0.8 }}
            className="vertical-line w-0.5 h-52 lg:h-64 bg-white mx-auto -mt-[5.9rem] md:-mt-[6.9rem] lg:-mt-[7.9rem]"
          >
            &nbsp;
          </motion.div>

          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 2.5, duration: 0.8 }}
            className="flex mx-auto justify-center items-center -mt-[6.6rem] md:-mt-[6.9rem] lg:-mt-[7.9rem] space-x-4"
          >
            <TeamMemberCard
              name={team[4].name}
              role={team[4].role}
              image={team[4].image}
              userid={team[4].userid}
            />
            <div className="vertical-line w-[40rem] h-0.5 bg-white">&nbsp;</div>
            <TeamMemberCard
              name={team[5].name}
              role={team[5].role}
              image={team[5].image}
              userid={team[5].userid}
            />
          </motion.div> */}
        </div>

        <section className="py-12">
          <div className="container mx-auto px-4 text-center w-11/12 md:max-w-5xl">
            <h2 className="text-xl font-medium md:text-3xl mt-4 md:!leading-[2.75rem]">
              Here at Sparkle, we focus on quality. We're dedicated to creating
              the best games on the ROBLOX platform. We're passionate about
              delivering experiences that are both immersive and engaging.
            </h2>

            <button
              className="bg-hero-gradient hover:saturate-150 text-white font-medium py-4 mt-12 px-6 rounded-lg transition-all duration-200 w-full md:w-auto"
              onClick={() => handleScrollTo("contact")}
            >
              Send us a message
            </button>
          </div>
        </section>
      </section>
    </>
  );
};

export default Who;
