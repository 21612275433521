import React from "react";

const GameGrid = ({ games }) => {
  // Sort games based on the 'playing' property in descending order
  const sortedGames = [...games].sort((a, b) => b.playing - a.playing);

  return (
    <div className="p-6">
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {sortedGames.map((game, index) => (
          <div key={index} className="bg-[#0c0c0c] p-4 rounded-lg">
            <img
              src={game.thumbnailUrl}
              alt={game.name}
              className="w-full h-48 object-cover rounded-lg mb-4"
            />
            <div className="text-white">
              <h2 className="font-black text-2xl overflow-hidden whitespace-nowrap overflow-ellipsis">
                {game.name}
              </h2>
              <p className="text-lg font-semibold">{game.genre}</p>
              <p className="text-md">{game.visits.toLocaleString()} visits</p>
              <p className="text-md">🔴 {game.playing} currently playing</p>
              <p className="text-md">
                ⭐ Favorited by {game.favoritedCount.toLocaleString()} users
              </p>
              <a
                href={`https://www.roblox.com/games/${game.rootPlaceId}`}
                target="_blank"
                rel="noopener noreferrer"
                className="inline-block mt-4 px-6 py-2 text-center text-white bg-blue-600 rounded-lg hover:bg-blue-500 transition"
              >
                View Game
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default GameGrid;
