import React, { useState } from "react";
import emailjs from "emailjs-com";

const Contact = () => {
  const [formData, setFormData] = useState({
    from_name: "",
    reply_to: "",
    subject: "",
    message: "",
  });

  const [errors, setErrors] = useState({});
  const [notification, setNotification] = useState({ type: "", message: "" });
  const [loading, setLoading] = useState(false); // Loading state

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateForm = () => {
    const errors = {};
    if (!formData.from_name) errors.from_name = "Name is required";
    if (!formData.reply_to) {
      errors.reply_to = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.reply_to)) {
      errors.reply_to = "Email is invalid";
    }
    if (!formData.subject) errors.subject = "Subject is required";
    if (!formData.message) errors.message = "Message is required";

    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    setLoading(true); // Set loading to true

    emailjs
      .send(
        "service_rsba7gc",
        "template_9tjafyn",
        formData,
        "zxJWw1e5GGPh0f1a3"
      )
      .then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);
          setNotification({
            type: "success",
            message: "Your message has been sent successfully!",
          });
          setFormData({
            from_name: "",
            reply_to: "",
            subject: "",
            message: "",
          });
          setErrors({});
          setLoading(false); // Set loading to false
        },
        (error) => {
          console.log("FAILED...", error);
          setNotification({
            type: "error",
            message: "Failed to send your message. Please try again.",
          });
          setLoading(false); // Set loading to false
        }
      );
  };

  return (
    <div
      className="min-h-screen flex items-center justify-center bg-[#080808] px-4"
      id="contact"
    >
      <div className="w-full max-w-2xl bg-[#0c0c0c] p-8 rounded-lg shadow-lg">
        <h2 className="text-4xl font-semibold text-white text-center mb-6">
          Contact Us
        </h2>
        <p className="text-gray-300 mb-8 text-center">
          We'd love to hear from you! Whether you have questions, feedback, or
          inquiries about acquisitions, brand partnerships, or other business
          opportunities, please fill out the form below. We'll get back to you
          as soon as possible.
        </p>
        {notification.message && (
          <div
            className={`text-center mb-4 p-2 rounded ${
              notification.type === "success"
                ? "bg-green-500 text-white"
                : "bg-red-500 text-white"
            }`}
          >
            {notification.message}
          </div>
        )}
        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label className="block text-sm font-medium text-gray-300">
              Name
            </label>
            <input
              type="text"
              name="from_name"
              value={formData.from_name}
              onChange={handleChange}
              className={`mt-1 block w-full px-4 py-2 bg-gray-700 border ${
                errors.from_name ? "border-red-500" : "border-gray-600"
              } rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 text-white`}
              required
            />
            {errors.from_name && (
              <p className="text-red-500 text-sm mt-1">{errors.from_name}</p>
            )}
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-300">
              Email
            </label>
            <input
              type="email"
              name="reply_to"
              value={formData.reply_to}
              onChange={handleChange}
              className={`mt-1 block w-full px-4 py-2 bg-gray-700 border ${
                errors.reply_to ? "border-red-500" : "border-gray-600"
              } rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 text-white`}
              required
            />
            {errors.reply_to && (
              <p className="text-red-500 text-sm mt-1">{errors.reply_to}</p>
            )}
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-300">
              Subject
            </label>
            <input
              type="text"
              name="subject"
              value={formData.subject}
              onChange={handleChange}
              className={`mt-1 block w-full px-4 py-2 bg-gray-700 border ${
                errors.subject ? "border-red-500" : "border-gray-600"
              } rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 text-white`}
              required
            />
            {errors.subject && (
              <p className="text-red-500 text-sm mt-1">{errors.subject}</p>
            )}
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-300">
              Message
            </label>
            <textarea
              name="message"
              value={formData.message}
              onChange={handleChange}
              className={`mt-1 block w-full px-4 py-2 bg-gray-700 border ${
                errors.message ? "border-red-500" : "border-gray-600"
              } rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 text-white`}
              rows="5"
              required
            ></textarea>
            {errors.message && (
              <p className="text-red-500 text-sm mt-1">{errors.message}</p>
            )}
          </div>
          <div>
            <button
              type="submit"
              className={`w-full py-3 bg-hero-gradient hover:saturate-150 text-white font-semibold rounded-md shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-all duration-200 flex items-center justify-center ${
                loading ? "opacity-50 cursor-not-allowed" : ""
              }`}
              disabled={loading}
            >
              {loading && (
                <svg
                  className="animate-spin h-5 w-5 mr-3 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8v8H4z"
                  ></path>
                </svg>
              )}
              {loading ? "Sending..." : "Send Message"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Contact;
